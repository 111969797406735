<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
   <div class="grid size-full place-items-center">
      <div class="flex flex-col place-items-center gap-4">
         <div class="size-6 animate-spin rounded-full border-2 border-secondary border-t-transparent" />
         <div class="text-secondary">
            {{ t('global.loading') }}...
         </div>
      </div>
   </div>
</template>
